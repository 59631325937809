<template>
<div class="download-page">
  <div class="page-art">
    <img class="art-img" src="/icons/icon-384x384.png" alt="">
  </div>

  <div class="page-content">
    <div class="content-title">老玩客運動</div>
  </div>

  <div class="page-install-message" v-if="showInstallMessage">
    <div class="message__container">
      <a-row>
        <a-col flex="50px">
          <div align="center">
            <span class="mdi mdi-plus-box-outline" :style="{ color: '#757575', fontSize: '32px' }" />
          </div>
        </a-col>
        
        <a-col flex="1" align="center">
           <div style="font-size:0.625rem;">下載「老玩客運動」在您的 iPhone:</div>
            <span>
              點擊 <span class="mdi mdi-export-variant" :style="{ color: '#1E88E5', fontSize: '24px' }" /> 加入主畫面
            </span>
        </a-col>
      </a-row>
    </div>
  </div>
</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  setup() {
    let showInstallMessage = false;

    // Detects if device is on iOS 
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test( userAgent );
    }
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => ('standalone' in window.navigator) && (window.navigator.standalone);

    console.log(isIos());
    console.log(isInStandaloneMode());

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      showInstallMessage = true;
    }

    return {
      showInstallMessage
    }
  },
  methods: {

  }
})
</script>

<style lang="scss">
.download-page {
  height: 100vh;
  background-color: rgba(255, 205, 171, 0.209);

  .page-art {
    display: flex;
    padding-top: 4.5rem;

    .art-img {
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0 15px 20px -10px rgba(0, 0, 0, 0.3) !important;
      height: 150px !important;
      width: 150px !important;
      margin: 0 auto;
      padding: 20px;
    }
  }

  .page-content {
    padding-top: 1.45rem;

    .content-title {
      letter-spacing: 0.0325em !important;
      text-align: center;
      font-size: 1.65rem;
      font-weight: 450;
    }
  }

  .page-install-message {
    width: 90%;
    position: fixed;
    bottom: 20px;
    left: 5%;
    background-color: #fff;
    border-radius: 4px;

    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 25px;
      height: 20px;
      background: #fff;
      top: calc(100% - 1px);
      left: calc(50% - 12.5px);
      // box-sizing: border-box;
      clip-path: polygon(51% 59%, 0 0, 100% 0);
    }

    .message__container {
      padding: 12px;
    }
  }
}
</style>